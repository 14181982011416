





































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import editView from './sys_intensity_edit.vue'
const columns = [
    {title: '阈值级别', key: 'levelText'},
    {title: '阈值下限（kV/m）', key: 'minValue'},
    {title: '阈值上限（kV/m）', key: 'maxValue'},
    {title: '修改时间', key: 'updateTime'},
    // // width: 400
    {title: '操作', slot: 'action'},
]
export default Vue.extend({
    components: {editView},
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            columns,
            data: []as any,
            changeDiv: false,
            index: 0,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            request.get('/admin/warning_threshold/query?element=electric_field_intensity&status=1', null)
            .then((data) => {
                // console.log(data.data)
                data.data.map((e: any, index: any) => {
                    e.index = index
                    e.levelText = this.getLevel(e.level)
                })
                this.data = data.data
                this.loading = false
            })
        },
        changeWarn(index: any) {
            this.changeDiv = true
            this.index = index
        },
        putSuccess() {
            this.changeDiv = false
            this.getData()
        },
        deleteItem(id: string) {
            // request.del(`/api/respond/respond_role/${id}`)
            // .then((res) => {
            //     this.$Message.success('删除成功!')
            //     this.getData()
            // }).catch((errorMsg) => {
            //     this.$Message.error(errorMsg)
            // })
        },
        /* 改变pageNum */
        changPageNum(num: number) {
            this.pageNum = num
            this.getData()
        },
        getLevel(data: any) {
            switch (data) {
                case '一级预警': return '一级阈值'
                case '二级预警': return '二级阈值'
                case '三级预警': return '三级阈值'
                case '四级预警': return '四级阈值'
                case '五级预警': return '五级阈值'
                default: return data
            }
        },
    },
})
