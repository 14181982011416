<template>
    <Layout class="edit-div">
        <Header class="header">
            <Breadcrumb class="breadcrumb">
                <!--  to="/sys_manage/oilInfo" -->
                <BreadcrumbItem>电场强度报警阈值配置</BreadcrumbItem>
                <BreadcrumbItem>修改</BreadcrumbItem>
            </Breadcrumb>
        </Header>
        <Content class="content">
            <Form ref="dataForm" :model="formData" :rules="rules" :label-width="100">
                <FormItem label="级别" prop="levelText">
                    <Input type="text" v-model="formData.levelText" disabled/>
                </FormItem>
                <FormItem label="阈值上限（km）" prop="maxValue">
                    <Input type="text" v-model="formData.maxValue" disabled/>
                </FormItem>
                <FormItem label="阈值下限（km）" prop="minValue">
                    <Input type="number" v-model="formData.minValue"/>
                </FormItem>
                <FormItem>
                    <Button style="background: #00A0E9;" type="primary" @click="submit()">保存</Button>
                </FormItem>
            </Form>
        </Content>
    </Layout>
</template>
<script>
import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
    props: {
        index: {
            type: Number,
        },
        data: {
            type: Array,
        },
    },
    data() {
        return {
            newDate: [],
            formData: {},
            rules: {
                // name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
                // lon: [{ required: true, message: '经度不能为空', trigger: 'blur' }],
                minValue: [{ required: true, type: 'number', message: '阈值下限不能为空', trigger: 'blur' }],
            },
        }
    },
    created() {
        this.newDate = JSON.parse(JSON.stringify(this.data))
        this.formData = this.newDate[this.index]
        // this.id = this.$route.params.id
        // this.getData()
    },
    methods: {
        submit() {
          // console.log(this.formData)
          this.formData.minValue = Number(this.formData.minValue)
          this.$refs.dataForm.validate((valid) => {
            if (valid) {
              if (this.index === 0) {
                if (Number(this.newDate[this.index].minValue) >= Number(this.newDate[this.index + 1].minValue)) {
                  return this.$Message.warning('阈值下限须小于阈值上限')
                }
              } else if (this.index === this.newDate.length - 1) {
                if (Number(this.newDate[this.index].minValue) <= Number(this.newDate[this.index - 1].minValue)) {
                  return this.$Message.warning('阈值下限须大于上一级阈值下限')
                }
              } else {
                if (Number(this.newDate[this.index].minValue) <= Number(this.newDate[this.index - 1].minValue) || Number(this.newDate[this.index].minValue) >= Number(this.newDate[this.index + 1].minValue)) {
                  return this.$Message.warning('阈值下限须介于上下级阈值下限之间')
                }
              }
              if (this.index === 0) {
                const params = JSON.parse(JSON.stringify(this.formData))
                delete params.status
                // console.log(params)
                request.put(`/admin/warning_threshold/${this.newDate[this.index].id}`, params)
                .then((res) => {
                  this.$Message.success('保存成功!')
                  // this.$router.replace('/sys_manage/oilInfo')
                  this.$emit('putSuccess')
                }).catch((err) => {
                  this.$Message.error(err)
                })
              } else {
                Promise.all([
                  this.putCurrent(),
                  this.putPgup(),
                ]).then(() => {
                  this.$Message.success('保存成功!')
                  this.$emit('putSuccess')
                })
              }
            }
          })
        },
        putCurrent() {
          const params = JSON.parse(JSON.stringify(this.formData))
          delete params.status
          // console.log(params)
          return request.put(`/admin/warning_threshold/${params.id}`, params)
          .then((res) => {
            // this.$Message.success('保存成功!')
            // this.$emit('putSuccess')
          }).catch((err) => {
            this.$Message.error(err)
          })
        },
        putPgup() {
          const params = JSON.parse(JSON.stringify(this.newDate[this.index - 1]))
          delete params.status
          params.maxValue = this.formData.minValue
          // console.log(params)
          return request.put(`/admin/warning_threshold/${params.id}`, params)
          .then((res) => {
            // this.$Message.success('保存成功!')
            // this.$emit('putSuccess')
          }).catch((err) => {
            this.$Message.error(err)
          })
        },
        // getData() {
        //     request.get(`/api/respond/respond_role/${this.id}`)
        //     .then((data) => this.formData = data)
        // },
    },
})
</script>
<style scoped>
  .ivu-layout {
    background: transparent;
    height: 100%;
  }
  .edit-div .header{
    height: 40px;
    line-height: 40px;
    background: transparent;
  }
  ::v-deep .ivu-breadcrumb{
    font-size: 16px;
  }
  ::v-deep .ivu-breadcrumb a{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb-item-separator{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb>span:last-child{
    color: #0077EE;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  form {
    width: 450px;
  }
  ::v-deep .ivu-form .ivu-form-item-label{
   color: #0077EE;
 }
 ::v-deep .ivu-input{
   background-color: transparent;
   color: #0077EE;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number-input{
   background-color: transparent;
   color: #0077EE;
 }
 ::v-deep .ivu-select-selection{
   background-color: transparent;
   border-color: #0077EE;
   color: #0077EE;
 }
 ::v-deep .ivu-spin-fix{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-table-wrapper>.ivu-spin-fix{
   border: none;
 }
</style>